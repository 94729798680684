import { Box, Button, Grid2, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import portrait from "../assets/portrait.jpg";
import TimelineComponent from "../components/TimelineComponent";

export default function About() {
  const openGitHub = () => {
    window.open("https://github.com/mdbatey", "_blank");
  };
  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/miles-batey", "_blank");
  };

  const openResume = () => {
    window.open("/Resume.pdf", "_blank");
  };

  return (
    <>
      <Box
        // minHeight={"50vh"}
        style={{
          backgroundColor: "#CFE8FC",
          // background: "linear-gradient(to bottom, #CFE8FC, #84C3F9)",
        }}
      >
        <section className="about-container">
          {/* <section style={{ paddingBottom: "10%" }}> */}
          <Box
            component="img"
            src={portrait}
            alt="Portrait"
            className="circleImage zIndex1"
          />
          <div className="about-info-container">
            <Typography variant="h2" sx={{ marginBottom: "5%" }}>
              Hi, I'm Miles!
            </Typography>
            <Typography sx={{ marginRight: "5%" }} variant="h4">
              I enjoy trail running, watching movies, and working on motivated
              teams!
            </Typography>
            <Typography sx={{ marginTop: "5%" }} variant="h5">
              Skills: React, React Native, NextJs, VueJs, NodeJs, AWS,
              Javascript, Python, C++, Java
            </Typography>
            <div className="icon-container">
              <div className="icon-link">
                <LinkedInIcon onClick={openLinkedIn} fontSize="large" />
              </div>
              <div className="icon-link">
                <GitHubIcon onClick={openGitHub} fontSize="large" />
              </div>
              <div style={{ display: "inline-block", margin: "3%" }}>
                <Button onClick={openResume} size="medium" variant="contained">
                  View Resume
                </Button>
              </div>
            </div>
          </div>
        </section>
      </Box>
      <Grid2
        height={"60vh"}
        className="spacer-image-2"
        // display={"flex"}
        // justifyContent={"center"}
        sx={{ marginTop: "-20%" }}
      ></Grid2>
      <Box>
        <Grid2
          // sx={{ backgroundColor: "#CFE8FC" }}
          display={"flex"}
          justifyContent={"center"}
          padding={"2%"}
        >
          <div className="title"> Experience</div>
        </Grid2>
        <TimelineComponent />
      </Box>
      <Grid2
        height={"60vh"}
        className="spacer-image"
        // display={"flex"}
        // justifyContent={"center"}
        sx={{ marginTop: "-20%" }}
      ></Grid2>
    </>
  );
}
